import dynamic from 'next/dynamic';

export default {
    HomePage: dynamic(() => import('./HomePage')),
    EstatePage: dynamic(() => import('./EstatePage')),
    DesignPage: dynamic(() => import('./DesignPage')),
    BriefFormPage: dynamic(() => import('./BriefFormPage')),
    FlexPage: dynamic(() => import('./FlexPage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
};
